export const environment = {
  mode: 'p',
  production: true,
  hmr: false,
  apiUrl: 'https://server.mrpro.cloud',
  //apiUrl: 'https://aspnetdevserver.mrpro.cloud',
  debugTranslation: false,
  okta: {
    redirectUri: 'https://mrpro.cloud/login/callback',
    logoutUri: 'https://mrpro.cloud/',
    clientId: '0oae9zfjl6aJOzafB0i7',
    domain: 'login.vossloh-digital.com',
    showLogin: true,
  },
  msalConfig: {
    redirectUri: 'https://mrpro.cloud',
    showLogin: true,
  },
  apiConfig: {
    scopes: ['ENTER_SCOPE'],
    uri: 'ENTER_URI',
  },
};

#zaehler-verwaltung {
  #genauSliders {
    .ngx-slider-span.ngx-slider-pointer {
      width: 15px;
      height: 15px;
      top: -6px;
      &::after {
        width: 5px;
        height: 5px;
        top: 5px;
        left: 5px;
      }
    }
  }
  
  div:has(> .zählerItemWrapper.card.item-table) {
    padding-top: 1px;
  }
  
  .zählerItemWrapper.card.item-table {
    height: calc(100% - 30px);
    min-height: calc(120px + 60px + 50px);
    > .card-header {
      height: 50px;
      form {
        .widthAuto > .clr-control-container {
          width: auto;
          flex: 0 1 auto;
        }
        .clr-subtext {
          text-wrap: nowrap;
        }
      }
      overflow: auto;
    }
    > .card-block {
      height: calc(100% - 50px - 60px);
    }
  }
  #grid-container {
    grid-template-columns: 1fr 24px;
    min-height: 50vh;
    align-items: start;
  }
  #wert-form { // .grid
    // grid-column: 1 / span 1; // default behaviour
    grid-template-columns: [nav-start] 1fr [nav-end slot-start] 1fr min-content [slot-end];
    grid-template-rows: [nav-start] min-content [nav-end];
    row-gap: 1.5em;
    column-gap: 0.5em;
    [role=grid-row-placeholder] {
      grid-row: 1;
    }
  
    #nav-row { // .grid
      grid-area: nav;
      grid-template-columns: 5fr 1fr 1fr 5fr;
      align-items: center;
      container-type: inline-size;
      @media (width < 768px) {
        grid-column: 1 / -1;
      }
      .btn-state {
        --btn-state-size: clamp(18px, 9cqi, 54px);
      }
  
      [app-area=prev-btn] {
        grid-column-start: 2;
      }
    }
  
    @media (768px <= width) {
      [app-area=is-header] {
        grid-column: span 1 / slot-end;
        grid-row: 3;
      }
      [app-area=einheit] {
        grid-row: 1;
        grid-column: slot-start / span 1;
      }
      [app-area=genau-sliders] {
        grid-row: 1;
        grid-column: span 1 / slot-end;
      }
    }
  
    #tol-block { // .grid
      gap: 12px;
      grid-template-columns: repeat(4, 1fr);
      @media (768px <= width) {
        grid-template-columns: min-content repeat(2, 1fr);
  

        [app-area$=aktiv], [app-area$=minus], [app-area$=plus] {
          grid-column: span 1;
        }
        
        @for $i from 1 through 3 {
          [app-area$=aktiv]:nth-of-type(#{$i}) {
            &,
            & + [app-area$=minus],
            & + [app-area$=minus] + [app-area$=plus],
            /* wenn minus nicht vorhanden, plus wird zum direct Sibling*/
            & + [app-area$=plus] {
              grid-row: #{$i};
            }
          }
        }
      }
    }
  
  
    
  }
}

#detailViewSidePanel > clr-modal > .modal {
  &, > .modal-backdrop {
    top: 3rem;
    height: calc(100vh - 3rem);
  }
  > .modal-dialog > .modal-content-wrapper > .modal-content {
    padding: 0;
    > .modal-footer, > .modal-header--accessible {
      display: none;
    }
  }
}
#wartungAccordion .clr-accordion-inner-content:has(clr-accordion-content.wartungArtItem) {
  padding: 0;
}

#maengel-grid-container {
  grid-template-rows: min-content max-content;
}